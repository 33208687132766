<template>
  <div class="inheritRate infoSection" ref="inheritRate">
    <h3 class="sectionTitle">
      <div class="title">
        상속재산 비율
        <span class="titleComment">모두 입력해주세요.</span>
      </div>
    </h3>

    <div
      class="inputContainer"
      @click="moveTopSection('.inheritRate')"
    >
      <div class="items">
        <div class="radioBox">
          <label class="baseInheritRate">
            <input
                id="baseInheritRate"
                name="shareOfInheritanceMethod"
                type="radio"
                v-model="shareOfInheritanceMethod"
                value="false"
                checked
            >
            <span>법적상속비율 적용</span>
          </label>
          <label class="customInheritRate">
            <input
                id="customInheritRate"
                name="shareOfInheritanceMethod"
                type="radio"
                v-model="shareOfInheritanceMethod"
                value="true"
            >
            <span>상속비율 직접입력</span>
          </label>
        </div>
        <div class="comment">
          <span v-if="shareOfInheritanceMethod === false">*법정상속비율: 유언으로 특징되지 않은 상속분이 민법에 따라 분배되는 비율</span>
          <span v-if="shareOfInheritanceMethod === true">*고객이 설정하는 임의의 상속 비율을 입력합니다.</span>
        </div>
        <div class="setInheritRate">
          <table>
            <tbody>
              <tr
                v-for="(inheritor, index) in shareOfInheritancesListByInheritor"
                :key="`shareOfInheritancesListByInheritor-${index}`"
              >
                <th>{{inheritor.type === "배우자" ? inheritor.type : inheritor.name }}</th>
                <td>
                  <label>
                    <input
                      type="text"
                      v-model.number="inheritor.shareOfInheritance"
                      :readonly="!shareOfInheritanceMethod"
                      @focusout="updateShareOfInheritancesListByInheritor"
                    />
                    <span class="count">%</span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div ref="errorComment" class="errorComment"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InheritRate",
  data() {
    return {
    }
  },
  computed: {
    shareOfInheritanceMethod: {
      get() { return this.$store.getters.isShareOfInheritanceMethod },
      set(data) {
        this.$store.commit('setShareOfInheritanceMethod', data === 'true')
        this.$store.commit('resetShareOfInheritancesListByInheritor', data === 'true')
      }
    },
    shareOfInheritancesListByInheritor() {
      return this.$store.getters.getShareOfInheritancesListByInheritor
    },
  },
  methods: {
    updateShareOfInheritancesListByInheritor() {
      this.$store.commit('updateShareOfInheritancesListByInheritor', this.shareOfInheritancesListByInheritor)
    },
    moveTopSection (target) {
      let tmp = document.querySelector(target).offsetTop - 66;
      document.querySelector('.inputArea').scrollTop = tmp;
    },
    checkForm() {
      let isError = false

      try {
        this.VALID.totalShareOfInheritance(this.$store.getters.getTotalShareOfInheritance)
        this.$refs.errorComment.innerText = ""
      } catch(err) {
        isError = true
        this.$refs.errorComment.innerText = "상속 비율의 총 합은 100%가 되어야 합니다."
      }

      return isError
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/inheritRate';
</style>
