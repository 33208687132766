<template>
  <div class="deduct infoSection" ref="deduct">
    <h3 class="sectionTitle">
      <div class="title">
        공제금액
        <span class="titleComment">보유자산만 입력해주세요.</span>
      </div>
    </h3>

    <div
      class="inputContainer flex"
      @click="moveTopSection('.deduct')"
    >
      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">배우자공제</span>
            <label>
              <span class="readOnly">{{ $comma(deductionsSpouseAmount) }}</span>
              <span>만원</span>
            </label>
          </li>
          <li>
            <span class="itemTitle">일괄공제</span>
            <label>
              <span class="readOnly">{{ $comma(deductionsBatchAmount) }}</span>
              <span>만원</span>
            </label>
          </li>
          <li>
            <span class="itemTitle">금융자산공제</span>
            <label>
              <span class="readOnly">{{ $comma(deductionsFinancialAssetsAmount) }}</span>
              <span>만원</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">동거주택상속공제</span>
            <label>
              <vue-numeric
                :min="0"
                :max="60000"
                placeholder="0"
                v-model="deductionsCohabitationHouseInheritanceAmount"
              />
              <span>만원</span>
            </label>
            <div class="comment">*동거주택가격과 60,000만원 중 적은 금액으로 입력합니다.</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="optionBox">
      <button
        class="calcBtn"
        @click="goToReportPage"
      >
        계산하기
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Deduct",
  computed: {
    deductionsSpouseAmount: {
      get() {
        return this.$store.getters.getDeductionsSpouseAmount },
    },
    deductionsBatchAmount: {
      get() { return this.$store.getters.getDeductionsBatchAmount },
    },
    deductionsFinancialAssetsAmount: {
      get() { return this.$store.getters.getDeductionsFinancialAssetsAmount },
    },
    deductionsCohabitationHouseInheritanceAmount: {
      get() { return this.$store.getters.getDeductionsCohabitationHouseInheritanceAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateDeductionsCohabitationHouseInheritanceAmount', data)
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
  },
  methods: {
    goToReportPage() {
      this.$emit('checkForFormContainer')
    },
    moveTopSection (target) {
      let tmp = document.querySelector(target).offsetTop - 66;
      document.querySelector('.inputArea').scrollTop = tmp;
    },
    checkForm() {
      let isError = false
      return isError
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/deduct';
</style>
