<template>
  <div class="header">
    <div class="headerTitle">상속 진단 리포트</div>
    <ul class="btnGroup">
      <li>
        <button
          @click="goTohome"
        >
          <img src="@/assets/img/common/icoHome.png" alt="">
        </button>
      </li>
      <li>
        <button
          @click="viewRotate180deg"
        >
          <img src="@/assets/img/common/icoRotate.png" alt="">
        </button>
      </li>
      <li>
        <button
          @click="logout">
          <img src="@/assets/img/common/icoLogout.png" alt="">
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "CommonHeader",
  props: {
    userInfo: {
      type: String,
      require: true
    }
  },
  methods: {
    viewRotate180deg () {
      document.querySelector('#app').classList.toggle('rotateView')
      this.trackRotateBtn()
    },
    goTohome() {
      if (this.$route.path === '/inheritForm') {
        document.querySelector('.inputArea').scrollTop = 0
      } else {
        this.$router.push({ name: 'inheritForm'})
      }
      this.trackHomeBtn()
    },
    /**
     * 로그아웃
     */
    logout () {
      // window.pruConfirm('로그아웃 하시겠습니까?<br>입력하신 정보는 저장되지 않습니다.',()=>{
      //   this.trackLogout();
      // })
      window.pruConfirm('상속 진단 리포트를 종료하시겠습니까?<br>입력하신 정보는 저장되지 않습니다.',()=>{
        this.trackLogout();
      })
    },
    trackLogout () {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_logout', parameterUser);
      this.$sessionStorageUtils.clear()
      this.$router.push({ name: 'reportEnd'})
    },
    trackHomeBtn () {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_homeBtn', parameterUser)
    },
    trackRotateBtn () {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_rotateBtn', parameterUser)
    }
  }
}
</script>
