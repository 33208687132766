<template>
  <div class="secondInherit infoSection" ref="secondInherit">
    <h3 class="sectionTitle">
      <div class="title">
        배우자 자산정보
        <span class="titleComment">2차상속 리포트 산출 시 입력해주세요.</span>
      </div>

      <label class="isVisibleSecondInherit" for="isVisibleSecondInherit">
        <input
          type="checkbox"
          id="isVisibleSecondInherit"
          v-model="visibleSecondInheritance"
        >
        2차상속 리포트 산출
        <div class="switchBtn">
          <span></span>
        </div>
      </label>
    </h3>

    <div
      class="inputContainer flex"
      @click="moveTopSection('.secondInherit')"
    >
      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">
              2차 상속 시점
            </span>
            <label>
              1차 상속의
              <vue-numeric
                placeholder="0"
                v-model="secondInheritanceYearsLater"
                :readonly="!visibleSecondInheritance"
                :readOnlyClass="!visibleSecondInheritance ? 'readOnly' : null"
              />
            </label>
            <span class="divideText">년 후</span>
            <div class="comment">*10년이내 단기 재상속 세액공제는 반영되지 않음</div>

            <div ref="errorComment" class="errorComment"></div>
          </li>
        </ul>
      </div>
      <div class="items">
        <ul>
          <li>
            <span class="itemTitle">
              비금융자산
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                placeholder="0"
                v-model.number="assetSpouseNonFinancialAssetsAmount"
                :readonly="!visibleSecondInheritance"
                :readOnlyClass="!visibleSecondInheritance ? 'readOnly' : null"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select
                v-model="assetSpouseNonFinancialAssetsAscentRate"
                :disabled="!visibleSecondInheritance"
              >
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle">
              금융자산
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                placeholder="0"
                v-model.number="assetSpouseFinancialAssetsAmount"
                :readonly="!visibleSecondInheritance"
                :readOnlyClass="!visibleSecondInheritance ? 'readOnly' : null"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select
                v-model="assetSpouseFinancialAssetsAscentRate"
                :disabled="!visibleSecondInheritance"
              >
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SecondInherit",
  computed: {
    secondInheritanceYearsLater: {
      get() { return this.$store.getters.getSecondInheritanceYearsLater || '' },
      set(data) {
        if (typeof data === 'number') {
          this.$store.commit('updateSecondInheritanceYearsLater', data)
        }
      }
    },
    assetSpouseNonFinancialAssetsAmount: {
      get() { return this.$store.getters.getAssetSpouseNonFinancialAssetsAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetSpouseNonFinancialAssetsAscentRate: {
      get() { return this.$store.getters.getAssetSpouseNonFinancialAssetsAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS, 'ascentRate', data)) }
    },
    assetSpouseFinancialAssetsAmount: {
      get() { return this.$store.getters.getAssetSpouseFinancialAssetsAmount || ''},
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetSpouseFinancialAssetsAscentRate: {
      get() { return this.$store.getters.getAssetSpouseFinancialAssetsAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS, 'ascentRate', data)) }
    },
    visibleSecondInheritance: {
      get() { return this.$store.getters.isVisibleSecondInheritanceTaxReport },
      set() { this.$store.commit('switchVisibleSecondInheritanceTaxReport') }
    }
  },
  watch: {
    secondInheritanceYearsLater() {
      this.checkForm()
    }
  },
  methods: {
    assetsData(assetType, key, value) {
      return {
        type: assetType,
        key: key,
        value: value
      }
    },
    moveTopSection (target) {
      let tmp = document.querySelector(target).offsetTop - 66;
      document.querySelector('.inputArea').scrollTop = tmp;
    },
    checkForm() {
      let isError = false

      const visibleSecondInheritance = this.$store.state.visibleSecondInheritanceTaxReport
      const yearsLaterAge = this.$store.state.spouse.age + this.$store.state.yearsLater[0] + this.$store.state.yearsLater[1]
      const spouseCheck = yearsLaterAge > 100
      // const secondYearsLaterCheck = this.$store.getters.getSecondInheritanceYearsLater < 10

      if (visibleSecondInheritance && spouseCheck) {
        isError = true
        this.$refs.errorComment.innerText = "2차 상속 시점에 배우자 나이가 100세를 초과하지 않도록 입력해 주세요."
      } else {
        this.$refs.errorComment.innerText = ""
      }

      return isError
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/secondInherit';
</style>
