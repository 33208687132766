<template>
  <div class="assetsInfo infoSection" ref="assetsInfo">
    <h3 class="sectionTitle">
      <div class="title">
        자산정보
        <span class="titleComment">보유자산만 입력해주세요.</span>
      </div>
    </h3>

    <div
      class="inputContainer flex"
      @click="moveTopSection('.assetsInfo')"
    >
      <div class="items">
        <div class="itemGroupTitle">부동산 자산</div>
        <ul>
          <li>
            <span class="itemTitle">
              토지
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                placeholder="0"
                v-model="assetLandAmount"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetLandAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle">
              건물
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetBuildingAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetBuildingAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle">
              주택
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetHousingAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetHousingAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="items">
        <div class="itemGroupTitle">가업주식</div>
        <ul>
          <li>
            <span class="itemTitle">
              금액
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetFamilyStockAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetFamilyStockAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="items">
        <div class="itemGroupTitle">금융 자산</div>
        <ul>
          <li>
            <span class="itemTitle">
              예금
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                  v-model.number="assetDepositAmount"
                  placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetDepositAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle">
              주식
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetStockAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetStockAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle">
              기타금융자산<br/>
              (펀드, 채권 등)
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetOtherFinancialAssetsAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetOtherFinancialAssetsAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="items">
        <div class="itemGroupTitle"></div>
        <ul>
          <li>
            <span class="itemTitle">
              보험자산<br/>
              <i>(납입보험료 또는 해지환급금)</i>
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetInsuranceAssetsAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetInsuranceAssetsAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
          <li>
            <span class="itemTitle lifeInsurance">
              사망보험금<br/>
              (종신보험)
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetLifeInsuranceAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원</span>
          </li>
        </ul>
      </div>
      <div class="items">
        <div class="itemGroupTitle">기타자산</div>
        <ul>
          <li>
            <span class="itemTitle">
              기타자산
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model.number="assetOtherAssetsAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원 X 상승률</span>
            <div class="selectBox">
              <select v-model="assetOtherAssetsAscentRate">
                <option :value="(n - 1)" v-for="n in 11" :key="(n - 1)">{{ n - 1 }}</option>
              </select>
              <span class="count">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="items">
        <div class="itemGroupTitle">장례비용</div>
        <ul>
          <li>
            <span class="itemTitle">
              장례비용
            </span>
            <label for="funeral">
              <input
                  id="funeral"
                  type="text"
                  placeholder="0"
                  v-model.number="assetFuneralExpensesAmount"
                  readonly
              >
            </label>
            <span class="divideText">만원</span>
          </li>
          <li>
            <span class="itemTitle">
              채무
            </span>
            <label>
              <vue-numeric
                :min="0"
                :max="100000000"
                v-model="assetFinancialObligationAmount"
                placeholder="0"
              />
            </label>
            <span class="divideText">만원</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AssetsInfo",
  created() {
  },
  computed: {
    assetLandAmount: {
      get() { return this.$store.getters.getAssetLandAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.LAND, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetLandAscentRate: {
      get() { return this.$store.getters.getAssetLandAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.LAND, 'ascentRate', data)) }
    },
    assetBuildingAmount: {
      get() { return this.$store.getters.getAssetBuildingAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.BUILDING, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetBuildingAscentRate: {
      get() { return this.$store.getters.getAssetBuildingAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.BUILDING, 'ascentRate', data)) }
    },
    assetHousingAmount: {
      get() { return this.$store.getters.getAssetHousingAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.HOUSING, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetHousingAscentRate: {
      get() { return this.$store.getters.getAssetHousingAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.HOUSING, 'ascentRate', data)) }
    },
    assetFamilyStockAmount: {
      get() { return this.$store.getters.getAssetFamilyStockAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.FAMILY_STOCK, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetFamilyStockAscentRate: {
      get() { return this.$store.getters.getAssetFamilyStockAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.FAMILY_STOCK, 'ascentRate', data)) }
    },
    assetDepositAmount: {
      get() { return this.$store.getters.getAssetDepositAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.DEPOSIT, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetDepositAscentRate: {
      get() { return this.$store.getters.getAssetDepositAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.DEPOSIT, 'ascentRate', data)) }
    },
    assetStockAmount: {
      get() { return this.$store.getters.getAssetStockAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.STOCK, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetStockAscentRate: {
      get() { return this.$store.getters.getAssetStockAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.STOCK, 'ascentRate', data)) }
    },
    assetOtherFinancialAssetsAmount: {
      get() { return this.$store.getters.getAssetOtherFinancialAssetsAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_FINANCIAL_ASSETS, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetOtherFinancialAssetsAscentRate: {
      get() { return this.$store.getters.getAssetOtherFinancialAssetsAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_FINANCIAL_ASSETS, 'ascentRate', data)) }
    },
    assetInsuranceAssetsAmount: {
      get() { return this.$store.getters.getAssetInsuranceAssetsAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.INSURANCE_ASSETS, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetInsuranceAssetsAscentRate: {
      get() { return this.$store.getters.getAssetInsuranceAssetsAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.INSURANCE_ASSETS, 'ascentRate', data)) }
    },
    assetLifeInsuranceAmount: {
      get() { return this.$store.getters.getAssetLifeInsuranceAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.LIFE_INSURANCE, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetOtherAssetsAmount: {
      get() { return this.$store.getters.getAssetOtherAssetsAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_ASSETS, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
    assetOtherAssetsAscentRate: {
      get() { return this.$store.getters.getAssetOtherAssetsAscentRate },
      set(data) { this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.OTHER_ASSETS, 'ascentRate', data)) }
    },
    assetFuneralExpensesAmount: {
      get() { return this.$store.getters.getAssetFuneralExpensesAmount || '' },
    },
    assetFinancialObligationAmount: {
      get() { return this.$store.getters.getAssetFinancialObligationAmount || '' },
      set(data) {
        if (typeof data === 'number') {
          try {
            this.$store.commit('updateAssets', this.assetsData(this.ASSETS_TYPE.FINANCIAL_OBLIGATION, 'amount', data))
          } catch(err) {
            console.error(err)
          }
        }
      }
    },
  },
  methods: {
    assetsData(assetType, key, value) {
      return {
        type: assetType,
        key: key,
        value: value
      }
    },
    moveTopSection (target) {
      let tmp = document.querySelector(target).offsetTop - 66;
      document.querySelector('.inputArea').scrollTop = tmp;
    },
    checkForm() {
      let isError = false
      return isError
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/inheritAssetsInfo';
</style>
